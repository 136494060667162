import React from "react";
import theme from "theme";
import { Theme, Link, Image, Button, Box, Section, Text, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { TiArrowRightThick } from "react-icons/ti";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
			Recharged: A Journey | A cozy survival game | Hidden Jem Games
			</title>
			<meta name={"description"} content={"Recharged: A Journey is a cozy survival game where you navigate wildlands, overcome inner struggles, and find harmony through nature. Forage, farm, fight, and thrive—all at your own pace. There is no grand mission - just focus on surviving, exploring, and being yourself. From Hidden Jem Games."} />
			<meta property={"og:title"} content={"Recharged: A Journey | A cozy survival game | Hidden Jem Games"} />
			<meta property={"og:description"} content={"Recharged: A Journey is a cozy survival game where you navigate wildlands, overcome inner struggles, and find harmony through nature. Forage, farm, fight, and thrive—all at your own pace. There is no grand mission - just focus on surviving, exploring, and being yourself. From Hidden Jem Games."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_004_0031.png?v=2024-12-07T22:32:33.133Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Components.Header>
			<Override slot="linkBox" />
			<Override slot="image" />
			<Override slot="SectionContent" />
			<Override slot="box" />
			<Override slot="icon" sm-font="22px sans-serif" />
			<Override slot="icon1" sm-font="22px sans-serif" />
			<Override slot="icon2" sm-font="22px sans-serif" />
			<Override slot="image1" sm-height="22px" />
			<Override slot="linkBox1" />
		</Components.Header>
		<Section
			padding="100px 40px 100px 40px"
			quarkly-title="HeroBlock"
			background=" url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_004_0031.png?v=2024-12-07T22:32:33.133Z) center/cover repeat scroll padding-box"
			sm-padding="100px 20px 100px 20px"
			sm-background="url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_004_0031.png?v=2024-12-07T22:32:33.133Z) 30%/cover repeat scroll padding-box"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="80vh"
				sm-min-width="280px"
				order="0"
				align-self="auto"
				justify-content="flex-end"
				align-items="center"
				padding="0px 0px 0px 0px"
				display="flex"
				sm-min-height="80vh"
				position="relative"
				sm-align-items="center"
				sm-justify-content="flex-start"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				margin="0px 0 0px 0"
				sm-margin="60px 0 0 0"
				md-width="50%"
				md-margin="0px 0 0px 0"
				width="50%"
				sm-width="100%"
				lg-width="60%"
			>
				<Image
					src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23:56:28.447Z"
					display="block"
					height="180px"
					md-height="100px"
					lg-height="140px"
					srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_logo_outline_2.png?v=2024-12-10T23%3A56%3A28.447Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
				<Button
					margin="40px 0px 0px 0px"
					background="--color-indigo"
					border-radius="8px"
					type="link"
					text-decoration-line="initial"
					href="https://dashboard.mailerlite.com/forms/1225004/140194012679111900/share"
					sm-margin="32px 0px 0px 0px"
				>
					Coming to Steam: Stay tune!
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				md-justify-content="flex-end"
				sm-justify-content="center"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="none"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
					md-grid-template-rows="fit-content(100px)"
				>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01:23:13.148Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%281%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02:50:33.442Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.jpg?v=2024-12-08T02%3A50%3A33.442Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01:23:13.148Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208%283%29.png?v=2024-12-08T01%3A23%3A13.148Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01:23:13.143Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6754cbc29335410018c86ba6/images/1208.png?v=2024-12-08T01%3A23%3A13.143Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 36px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-text-align="left"
					sm-align-self="flex-start"
				>
					A Journey to Reconnect
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					md-text-align="left"
				>
					Imagine being dropped into the wild with nothing but yourself. To survive, you must adapt to nature—and through it, reconnect to your inner strength.
				</Text>
				<Box
					min-width="auto"
					min-height="auto"
					display="flex"
					align-items="flex-start"
					padding="8px 0px 0 0px"
					flex="0 0 auto"
				>
					<Icon
						category="ti"
						icon={TiArrowRightThick}
						size="24px"
						color="--color-indigo"
						hover-color="#a78bfa"
						transition="background-color 1s ease 0s"
						padding="4px 04px 0px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="center"
						padding="0 0px 0px 0px"
						md-text-align="left"
					>
						Learn to survive in the wild with unexpected danger and dynamic seasonal changes.{" "}
					</Text>
				</Box>
				<Box
					min-width="auto"
					min-height="auto"
					display="flex"
					align-items="flex-start"
					padding="8px 0px 0 0px"
					flex="0 0 auto"
					md-align-self="flex-start"
				>
					<Icon
						category="ti"
						icon={TiArrowRightThick}
						size="24px"
						color="--color-indigo"
						hover-color="#a78bfa"
						transition="background-color 1s ease 0s"
						padding="4px 04px 0px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="center"
						padding="0 0px 0px 0px"
						md-text-align="left"
					>
						Play as different characters, each with their own struggles.
					</Text>
				</Box>
				<Box
					min-width="auto"
					min-height="auto"
					display="flex"
					align-items="flex-start"
					padding="8px 0px 0 0px"
					flex="0 0 auto"
					md-align-self="flex-start"
				>
					<Icon
						category="ti"
						icon={TiArrowRightThick}
						size="24px"
						color="--color-indigo"
						hover-color="#a78bfa"
						transition="background-color 1s ease 0s"
						padding="4px 04px 0px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="center"
						padding="0 0px 0px 0px"
						md-text-align="left"
					>
						Forage, farm, fish to sustain yourself.
					</Text>
				</Box>
				<Box
					min-width="auto"
					min-height="auto"
					display="flex"
					align-items="flex-start"
					padding="8px 0px 0 0px"
					flex="0 0 auto"
					md-align-self="flex-start"
				>
					<Icon
						category="ti"
						icon={TiArrowRightThick}
						size="24px"
						color="--color-indigo"
						hover-color="#a78bfa"
						transition="background-color 1s ease 0s"
						padding="4px 04px 0px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="center"
						padding="0 0px 0px 0px"
						md-text-align="left"
					>
						Explore a world brimming with hidden secrets, surprises, and chance encounters.
					</Text>
				</Box>
				<Box
					min-width="auto"
					min-height="auto"
					display="flex"
					align-items="flex-start"
					padding="8px 0px 0 0px"
					flex="0 0 auto"
					md-align-self="flex-start"
				>
					<Icon
						category="ti"
						icon={TiArrowRightThick}
						size="24px"
						color="--color-indigo"
						hover-color="#a78bfa"
						transition="background-color 1s ease 0s"
						padding="4px 04px 0px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--color-indig"
						font="--lead"
						lg-text-align="center"
						padding="0 0px 0px 0px"
						md-text-align="left"
					>
						A deeply personal journey with no grand missions - just focus on surviving, exploring, and being yourself.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark" background="--color-light">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQs
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Need answers? I got’em
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0">
									What platforms will the game be available on?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
										color="--color-indigo"
									>
										Recharged: A Journey
									</Strong>
									{" "}will be launched first on PC/Steam. After the game is released, I will try to make it available on other platforms.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0">
									When will the game be released?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Unfortunately, I don’t have an exact release date just yet. But I’m working hard to get there and making progress everyday. Being a solo developer, I really appreciate your patience and support. It truly means a lot to me!{" "}
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0">
									Will there be a demo?
									<br />
									{" "}
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Yes! A playable demo will be available soon to give you a taste of the adventure. Stay tune!
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline4" margin="12px 0">
									How can I stay updated on the game’s progress?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Sign up for our{" "}
									<Link href="https://dashboard.mailerlite.com/forms/1225004/140194012679111900/share" color="--primary">
										newsletter{" "}
									</Link>
									and follow us on social media (
									<Link
										href="https://www.tiktok.com/@hiddenjem09"
										color="--color-indigo"
										text-decoration-line="underline"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										Tiktok
									</Link>
									,{" "}
									<Link
										href="https://x.com/recharged_hj"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
										color="--color-indigo"
									>
										Twitter
									</Link>
									,{" "}
									<Link
										href="https://www.instagram.com/recharged_a_journey"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
										color="--color-indigo"
									>
										Instagram
									</Link>
									,{" "}
									<Link
										href="https://www.youtube.com/@recharged_hj"
										color="--color-indigo"
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										Youtube
									</Link>
									). I'm posting game updates, devlogs and behind-the-scenes stuff frequently on these channels.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});